import { Controller } from "@hotwired/stimulus"

//
//== FANCYBOX
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  connect () {
    Fancybox.bind("[data-fancybox]", {
      parent: 'body',
      Toolbar: {
        display: [
          "zoom",
          "slideshow",
          "fullscreen",
          "thumbs",
          "close"
        ]
      }
    })
  }

  disconnect () {
    Fancybox.destroy();
  }
}
